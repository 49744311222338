.form-fieldset,
.form-layout {
  max-width: 100% !important;
}
.card-body {
  flex: 1 1 auto;
  padding: 40px 40px 40px 40px;
}
.dataTable-wrapper .dataTable-container .dataTable-table th:last-child,
.dataTable-wrapper .dataTable-container .dataTable-table td:last-child {
  text-align: left;
}
.bg-gray,
table.dataTable-table tr.bg-gray >* {
  background: #f1f3fa !important;
}
.form-seperator {
  border-bottom: 1px solid #ddd;
  margin-bottom: 2vh;
}
.text-muted {
  color: #bdbdbd;
}
// table

table.dataTable-table thead th {
  position: sticky;
  top: 0;
  z-index: 55;
  background: #fff !important;
  //padding: 0.5rem 1rem;
}
table.dataTable-table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 66;
   background-color: #fff !important;
}
table.dataTable-table tbody th {
  position: sticky;
  left: 0;
  background: #fff !important;
  z-index: 55;
  min-width: 30rem;
}
tr.bg-gray th {
  background-color: #f1f3fa !important;
}
div.dataTable-wrapper .dataTable-container {
  max-height: 72vh;
  background: #fff;
  border: none !important;
  overflow-x: scroll;
  overflow-y: scroll;
  display: block;
  //scrollbar-width: thin;
}

//table.dataTable-table td,
//table.dataTable-table th {
//  padding: 0.5rem 1rem;
//}
table.dataTable-table {
  white-space: nowrap;
  border-collapse: separate !important;
  border-spacing: 0;
}
*::-webkit-scrollbar {
  width: 8px;
}