// 
// right-sidebar.scss
//

/* rtl:begin:options: {
  "autoRename": true,
  "stringMap":[]
} */

.end-bar , .right-sidebar-end-bar{
    background-color: $rightbar-bg;
    box-shadow: 0 0 24px 0 rgba($dark, 0.1), 0 1px 0 0 rgba($dark, 0.08);
    display: block;
    position: fixed;
    transition: all 200ms ease-out;
    width: $rightbar-width;
    z-index: 9999;
    float: right !important;
    right: -($rightbar-width + 10px);
    top: 0;
    bottom: 0;
    padding-bottom: 80px;

    .rightbar-title {
        background-color: $rightbar-title-bg;
        padding: 16px;
        color: $rightbar-title-color;
        .title{
            font-size: 16px;
        }
    }

    .sub-section-wrapper{
        width: 90%;
    }

}
.end-bar-toggle {
    background-color: $rightbar-title-btn-bg;
    height: 24px;
    width: 24px;
    line-height: 25px;
    color: $rightbar-title-btn-color;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px;
}
.badge{
    width: fit-content;
}
.rightbar-overlay, .right-sidebar-overlay {
    background-color: $rightbar-overlay-bg;
    opacity: 0.2;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    transition: all .2s ease-out;
}

.end-bar-enabled {
    .end-bar {
        right: 0;
    }
    .rightbar-overlay {
        display: block;
    }
}
.show-sidebar {
    .right-sidebar-end-bar {
        right: 0;
    }

    .right-sidebar-overlay {
        display: block;
    }
}


@include media-breakpoint-down(md) {
    .end-bar,.right-sidebar-end-bar {
        overflow: auto;
    }
}