.rpd-light-gray-color{
  color:#c7c6c8
}
#short-name{
  background: #727cf5;
  border-radius: 100px;
  width: 120px;
  height: 120px;
  h1 {
    color: #ffffff;
  }
}
#bird-eye-reviews .mdi-star{
  color: #aaaaaa;
}
#bird-eye-reviews .active-star{
  color: orange;
}
#review-business-response{
  background: #f1f1f1;
  border-radius: 10px;
  padding: 10px;
}
@media (max-width: 768px) {
  #short-name{
    width: 100px;
    height: 100px;
  }
}
.apexcharts-tooltip {
  background: #f3f3f3 !important;
  color: orange !important;
}
table tr.sub-source > * {
  background-color: white !important;
}
//.apexcharts-gridline:nth-child(2) {
//  stroke-dasharray: 10 !important;
//  border-color: red !important ;
//}

